html {
  height: 100%;
}

body,
#__next {
  /*
   * ref: https://stackoverflow.com/a/21836870
   */
  min-height: 100%;
  height: 1px;
}
